import * as React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import SecondaryWideLayout from '../../components/secondary-wide-layout';
import LandingHeader, { LandingHeaderStyle } from '../../components/landing-header';
import LandingBlock, { LandingBlockStyle } from '../../components/landing-block';
import FooterGetStarted from '../../components/footer-get-started';
import Meta from '../../components/meta';

import * as Text from '../../localisation';
import { Url } from '../../url';

import Projects from '../../images/freelancers-projects.svg';
import Reports from '../../images/freelancers-reports.svg';

function Freelancers() {
  return (
    <>
      <Meta
        title={Text.FreelancersPageTitle}
        url={Url.UseCaseFreelancersAndConsultants}
      />

      <SecondaryWideLayout>
        <LandingHeader
          style={LandingHeaderStyle.GreenTitle}
          title={Text.FreelancersHeaderTitle}
          text={Text.FreelancersHeaderText}
        />

        <LandingBlock
          title={Text.FreelancersGeneralTitle}
          text={Text.FreelancersGeneralText}
          picture={(
            <StaticImage
              src="../../images/freelancers-app-preview.png"
              alt={Text.FreelancersGeneralPictureAlt}
              layout="fullWidth"
              objectFit="contain"
            />
          )}
          pictureShadowShown
          pictureBorderRound
          style={LandingBlockStyle.Gray}
        />

        <LandingBlock
          title={Text.FreelancersProjectsTitle}
          text={Text.FreelancersProjectsText}
          picture={(
            <img
              src={Projects}
              alt={Text.FreelancersProjectsPictureAlt}
            />
          )}
          style={LandingBlockStyle.White}
        />

        <LandingBlock
          title={Text.FreelancersMeetingsTitle}
          text={Text.FreelancersMeetingsText}
          picture={(
            <StaticImage
              src="../../images/freelancers-meeting-preview.png"
              alt={Text.FreelancersMeetingsPictureAlt}
              layout="fullWidth"
            />
          )}
          pictureShadowShown
          pictureBorderRound
          style={LandingBlockStyle.Black}
        />

        <LandingBlock
          title={Text.FreelancersReportsTitle}
          text={Text.FreelancersReportsText}
          picture={(
            <img
              src={Reports}
              alt={Text.FreelancersReportsPictureAlt}
            />
          )}
          style={LandingBlockStyle.Gray}
        />

        <FooterGetStarted />
      </SecondaryWideLayout>
    </>
  );
}

export default Freelancers;
